import { useRouter } from 'next/router';

const Algolia = ({ data, clickOnTerm }) => {
  const router = useRouter();

  if (!data || data.length === 0) {
    return '';
  }

  return (
    <div className="hits">
      {data.map((item, index) => (
        <div
          key={index}
          className="div-algolia-link hit"
          onClick={() => {
            if (typeof window === 'undefined') {
              return;
            }

            if (router.query.url === 'global-search-page') {
              clickOnTerm(item.query);

              router.push({
                pathname: '/global-search-page',
                query: { search: item.query },
              });
              return;
            }

            clickOnTerm(item.query);
            router.push({
              pathname: '/global-search-page',
              query: { search: item.query },
            });
          }}
        >
          <h2>{item.query}</h2>
        </div>
      ))}
    </div>
  );
};

export default Algolia;
